<template>
    <div :class="`wrapper wrapper--default m-3 p-2`">
        <div class="text-center p-2">
            <h2>
                Open posten Jaar: {{ year }}, Maand: {{ month }}
            </h2>
            <span @click="previousMonth"><i class="fas fa-arrow-left"></i></span>
            <span class="text-center m-2">Maand: {{ month }}</span>
            <span @click="nextMonth"><i class="fas fa-arrow-right"></i></span>
        </div>
        <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
        <Loading v-if="loading" />
        <table v-if="!loading" :class="`table--perweek table__border--default responsive`">
            <thead class="table__head--default">
                <tr>
                    <th class="table__head--text">Doc Type</th>
                    <th class="table__head--text">Doc nummer</th>
                    <th class="table__head--text" style="min-width: 100px">Factuur datum</th>
                    <th class="table__data--right table__head--text" style="min-width: 100px">
                        Totaal bedrag
                    </th>
                    <th class="table__data--right table__head--text">
                        Openstaand
                    </th>
                    <th class="table__data--right table__head--text">Klantnaam</th>
                    <th class="table__data--right table__head--text">Land</th>
                    <th class="table__data--right table__head--text">Omschrijving</th>
                    <th class="table__data--right table__head--text">Bank omschrijving A</th>
                    <th class="table__data--right table__head--text">Bank omschrijving B</th>
                    <th class="table__data--right table__head--text">Valuta</th>
                    <th class="table__data--right table__head--text">
                        Valuta bedrag
                    </th>
                    <th class="table__data--right table__head--text">Valuta open</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in open_posts_per_month" :key="key" class="table__row--color">
                    <td class="table__cell--default">{{ item.type }}</td>
                    <td class="table__cell--default">{{ item.factuur }}</td>
                    <td class="table__cell--default">{{ item.datum }}</td>
                    <td class="table__data--right table__cell--default">
                        € {{ item.bedrag }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        {{ item.openstaand }}
                    </td>
                    <td class="'table__data--right table__cell--defaul'">
                        {{ item.klantnaam }}
                    </td>
                    <td class="'table__data--right table__cell--defaul'">
                        {{ item.land }}
                    </td>
                    <td class="table__cell--default">
                        {{ item.omschrijving }}
                    </td>
                    <td class="table__cell--default" style="max-width: 300px;">
                        <div style="text-wrap: wrap;overflow-wrap: break-word;">
                            {{ item.bankomschrijvingA }}
                        </div>
                    </td>
                    <td class="table__cell--default" style="max-width: 300px;">
                        <div style="text-wrap: wrap;overflow-wrap: break-word;">
                            {{ item.bankomschrijvingB }}
                        </div>
                    </td>
                    <td class="table__data--right table__cell--default">
                        {{ item.valuta }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        {{ item.valutabedrag }}
                    </td>
                    <td class="table__data--right table__cell--default">
                        {{ item.openvaluta }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- </div> -->
</template>
  
<script>
import request from "@/functions/request.js";
import moment from "moment";
import Loading from "@/components/Loading.vue";
import { subtractTime, addTime } from "@/functions/date.js";
import * as XLSX from "xlsx";

export default {
    components: { Loading },
    props: ["bu"],
    data: () => ({
        loading: null,
        open_posts_per_month: null,
        first_day: null,
        last_day: null,
        full_date: moment(),
        month: null,
        year: null,
    }),
    created() {
        this.getData(this.bu);
    },
    methods: {
        getData() {
            this.loading = true;
            this.month = moment(this.full_date).month() + 1;
            this.first_day = moment(this.full_date)
                .startOf("month")
                .format("YYYY-MM-DD");
            this.last_day = moment(this.full_date)
                .endOf("month")
                .format("YYYY-MM-DD");
            this.year = moment(this.full_date).year();
            request(
                `open-posts-per-month/${this.first_day}/${this.last_day}`,
                "GET"
            ).then(({ open_posts_per_month }) => {
                this.open_posts_per_month = open_posts_per_month;
                this.loading = false;
            });
        },

        previousMonth() {
            this.full_date = subtractTime(1, this.full_date, "months", "YYYY/MM/DD");
            this.getData(this.bu);
        },

        nextMonth() {
            this.full_date = addTime(1, this.full_date, "months", "YYYY/MM/DD");
            this.getData(this.bu);
        },

        createExcel() {
            let open_posts = [];
            this.open_posts_per_month.forEach((i) => {
                open_posts.push({
                    "Doc type": i.type,
                    "Doc nummer": i.factuur,
                    "Factuur Datum": i.datum,
                    "Totaal bedrag": i.bedrag,
                    "Klant naam": i.klantnaam,
                    "Klant land": i.land,
                    "Openstaand": i.openstaand,
                    "Omschrijving": i.omschrijving,
                    "Bank omschrijving A": i.bankomschrijvingA,
                    "Bank omschrijving B": i.bankomschrijvingB,
                    "Valuta": i.valuta,
                    "Valuta bedrag": i.valutabedrag,
                    "Valuta open": i.openvaluta,
                });
            });

            var telWS = XLSX.utils.json_to_sheet(open_posts);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                wb,
                telWS,
                `Openstaande_posten_${this.moment().format("DD-MM-YYYY")}`
            );
            XLSX.writeFile(
                wb,
                `Openstaande_posten_${this.first_day}_${this.last_day}.xlsx`
            );
        },
    },
};
</script>